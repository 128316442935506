<template>
  <div class="container">
    <rtg-heading
      title="CONCEPT"
      title-class="concept"
    />

    <rtg-content
      type="col"
      image-name="image2"
      image-alt="ルーテイジとは"
      class="consept-top"
    >
      <span>ルーテイジ</span>
      という言葉は、
      <span>根付く</span>
      という意味があります。
      <br>
      地面に根を張る大樹の様に、社員が
      <span>技術力</span>
      を伸ばし、会社と共に成長していく。
      <br>
      私たちの社名には、
      <span>社会に根付く</span>
      という意味が込められています。
    </rtg-content>

    <rtg-heading
      type="h3"
      title="企業理念"
      sub-title="Corporate philosophy"
    />

    <rtg-content
      type="col"
      image-name="image1"
      image-alt="本気の遊び"
    >
      エンジニアに必要な
      <span>スキル</span>
      <span>コミュニケーション</span>
      <span>アイデア</span>
      は
      <span>遊び心</span>
      から生まれるものだと確信しています。
      <br>
      <span>本気の遊び</span>
      とは、社員が仕事を通じ人生を豊かにする為の術を、この会社で見つけて欲しいという願いから生まれた、ルーテイジの
      <span>企業理念</span>
      です。
    </rtg-content>
  </div>
</template>
<script>
import RtgHeading from './RtgHeading.vue'
import RtgContent from './RtgContent.vue'

export default {
  name: 'App',
  components: {
    RtgHeading,
    RtgContent
  }
}
</script>

<style scoped lang="scss">
.consept-top {
  margin-top: 80px;
}

@include mq-down(md) {
  .consept-top {
    margin-top: 40px;
  }
}

</style>
