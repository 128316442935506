<template>
  <svg
    class="svg-image"
    :width="svgData.svgDataWidth"
    :height="svgData.svgDataHeight"
    :viewBox="svgData.svgDataViewBox"
  >
    <title>
      {{ svgData.title }}
    </title>
    <path :d="svgData.svgDataPath" />
  </svg>
</template>
<script>
import RtgSvgPath from './RtgSvgPath.js'
export default {
  props: {
    name: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      svgCodePath: RtgSvgPath,
      svgData: {}
    }
  },
  created () {
    for (const key in this.svgCodePath) {
      if (this.name === key) {
        this.svgData = {
          svgDataWidth: this.svgCodePath[key].width,
          svgDataHeight: this.svgCodePath[key].height,
          svgDataViewBox: this.svgCodePath[key].viewBox,
          svgDataPath: this.svgCodePath[key].path
        }
      }
    }
  }
}
</script>
