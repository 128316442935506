<template>
  <transition name="slide">
    <div
      v-if="showDrawer"
      class="app-drawer"
    >
      <div
        ref="content"
        class="app-drawer__content"
        @click="$_close"
      >
        <rtg-nav-list :nav-list-data="navListData" />
      </div>
    </div>
  </transition>
</template>
<script>
import RtgNavList from './RtgNavList.vue'
export default {
  components: {
    RtgNavList
  },
  model: {
    prop: 'showDrawer',
    event: 'input'
  },
  props: {
    showDrawer: {
      type: Boolean,
      default: false,
      required: true
    },
    navListData: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    $_close () {
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped lang="scss">
.app-drawer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  .app-drawer__content {
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    background: $rtgBlue;

    ::v-deep(nav) {
      height: 100vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      a {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .image {
        width: 48px;
        margin: 0 20px 0 0;
      }

      .text {
        width: 110px;
        font-size: 16px;
        justify-content: flex-start;
      }
    }
  }
}
</style>
