<template>
  <a
    class="button"
    href="https://forms.gle/iziHL5r3XWfan5pE7"
    target="_blank"
  >
    <span>お問い合わせ</span>
    <span>ビジネスパートナー様</span>
  </a>
</template>
<style scoped lang="scss">
.button {
  font-weight: $fw-B;
  background: $primary;
  color: $shade;
  border: none;
  width: 320px;
  padding: 8px 16px;
  font-size: 24px;
  border-radius: 48px;
  box-shadow: 0 10px 0 rgb(0 0 0 / 0.8);
  opacity: 1;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-decoration: none;

  &::before {
    background-color: #fff;
    content: "";
    display: block;
    position: absolute;
    top: -100px;
    left: 0;
    width: 30px;
    height: 100%;
    opacity: 0;
    transition: cubic-bezier(0.32, 0, 0.67, 0);
  }

  &:hover {
    &::before {
      animation: kiran 0.5s linear 1;
    }
  }

  span {
    &:last-child {
      font-size: 10px;
    }
  }
}

@include mq-down(sm) {
  .button {
    width: 250px;
    font-size: 20px;
    box-shadow: 0 5px 0 rgb(0 0 0 / 0.8);
  }
}

</style>
