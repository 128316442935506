<template>
  <div class="container info">
    <div
      v-if="isMobile ()"
      class="bg-image"
    />
    <video
      v-else
      class="bg-video"
      loop
      muted
      data-autoplay
      playsinline
    >
      <source
        src="@/assets/images/movie.mp4"
        type="video/mp4"
      >
    </video>

    <div class="bg">
      <div
        v-for="n in 5"
        :key="n"
        class="bg__items"
        :class="`bg__items--${n}`"
      >
        <div class="bg__items__inner" />
      </div>
    </div>

    <a
      class="arrow"
      href="#concept"
    >
      <span />
    </a>
    <rtg-svg-image
      name="svgLogoType"
      class="logo"
    />
    <rtg-svg-image
      name="svgCatchCopy"
      class="catch"
    />
    <div class="info-list">
      <div class="info-list__title">
        Information
      </div>
      <div class="info-list__inner">
        <!-- <a
          href="https://next.rikunabi.com/company/cmi3002726003/nx1_rq0026590536/"
          target="_blank"
        >
          <span class="label label--1">営業職募集</span>
          <p>【リクナビNEXT】にて、営業職募集中！</p>
        </a>
        <a
          href="https://tenshoku.mynavi.jp/jobinfo-343074-2-3-1"
          target="_blank"
        >
          <span class="label label--2">エンジニア募集</span>
          <p>【マイナビ転職】にて、ITエンジニア募集中！</p>
        </a>
        <a
          href="https://employment.en-japan.com/desc_1285792"
          target="_blank"
        >
          <span class="label label--2">エンジニア募集</span>
          <p>【en転職】にて、ITエンジニア募集中！</p>
        </a> -->
        <a
          href="https://doda.jp/DodaFront/View/JobSearchDetail/j_jid__3010873119"
          target="_blank"
        >
          <span class="label label--2">エンジニア募集</span>
          <p>【duda】にて、ITエンジニア募集中！</p>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import RtgSvgImage from './RtgSvgImage.vue'
export default {
  components: {
    RtgSvgImage
  },
  methods: {
    isMobile () {
      const userAgent = window.navigator.userAgent.toLowerCase()
      if (
        userAgent.indexOf('mobile') !== -1
      ) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
}

.bg-video {
  opacity: 0.5;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: contain;
  object-fit: cover;
}

.bg-image {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(~@/assets/images/bg.jpg);
  background-size: cover;
  opacity: 0.3;
  animation: bgMoveLeft 80s infinite linear;
}

.bg {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60vh;
  display: flex;

  .bg__items--1 {
    background-image: url(~@/assets/images/bg1.png);

    .bg__items__inner {
      background-image: url(~@/assets/images/bg1.svg);
    }
  }

  .bg__items--2 {
    background-image: url(~@/assets/images/bg2.png);
    .bg__items__inner { background-image: url(~@/assets/images/bg2.svg); }
  }

  .bg__items--3 {
    background-image: url(~@/assets/images/bg3.png);
    .bg__items__inner { background-image: url(~@/assets/images/bg3.svg); }
  }

  .bg__items--4 {
    background-image: url(~@/assets/images/bg4.png);
    .bg__items__inner { background-image: url(~@/assets/images/bg4.svg); }
  }

  .bg__items--5 {
    background-image: url(~@/assets/images/bg5.png);
    .bg__items__inner { background-image: url(~@/assets/images/bg5.svg); }
  }

  .bg__items {
    width: calc(100% / 5);
    background-repeat: no-repeat;
    background-size: cover;

    .bg__items__inner {
      height: 100%;
      background-repeat: no-repeat;
      background-position: bottom center;
    }
  }
}

.logo {
  position: absolute;
  fill: #fff;
  top: 200px;
  left: 50%;
  margin-left: -75px;
}

.catch {
  position: absolute;
  fill: #fff;
  top: 350px;
  left: 50%;
  margin-left: -400px;
}

.arrow {
  animation: 1s infinite alternate arrowBounce;
  padding: 32px;
  position: absolute;
  bottom: 10%;
  left: 50%;
  margin-left: -48px;

  span {
    display: block;
    width: 50px;
    height: 50px;
    border-right: 3px solid;
    border-bottom: 3px solid;
    border-color: $shade;
    transform: rotate(45deg);
  }
}

.info-list {
  position: absolute;
  bottom: 15%;
  width: 100%;
  padding: 0 16px;

  &__inner {
    margin: 0 auto;
    background-color: $shade;
    max-width: 640px;
    border-radius: 8px;
    padding: 0 16px;
    border: 2px solid $border;
  }

  &__title {
    font-size: 24px;
    color: $shade;
    text-align: center;
    margin-bottom: 8px;
  }

  a {
    &:first-child {
      border-bottom: 1px solid $border;
    }

    &:last-child {
      border-bottom: none;
    }

    display: flex;
    padding: 16px 32px 16px 0;
    color: $primary;
    text-decoration: none;
    font-size: 16px;
    font-weight: $fw-B;
    position: relative;

    &:hover {
      text-decoration: underline;
    }

    &::after {
      background-image: url(~@/assets/images/icon-arrow-right.svg);
      display: block;
      content: "";
      position: absolute;
      right: 0;
      bottom: 50%;
      margin-bottom: -12px;
      width: 24px;
      height: 24px;
    }

    p {
      flex: 1;
    }
  }

  .label {
    background: red;
    border-radius: 4px;
    padding: 2px 4px;
    font-size: 14px;
    color: $shade;
    margin-right: 8px;
    width: 120px;
    display: block;
    text-align: center;
    height: 24px;

    &--1 {
      background: #19c34d;
    }

    &--2 {
      background: $primary;
    }
  }
}

@include mq-up(lg) {
  .bg {
    .bg__items {
      &--1,
      &--2,
      &--5 {
        animation: bgMoveLeft 80s infinite linear;
      }

      &--3,
      &--4 {
        animation: bgMoveRight 80s infinite linear;
      }
    }
  }
}

@include mq-down(lg) {
  .logo {
    top: 50%;
    margin-top: -30%;
    margin-left: -75px;
    width: 150px;
  }

  .catch {
    top: 50%;
    margin-top: -10%;
    left: 50%;
    margin-left: -40%;
    width: 80%;
  }

  .bg {
    .bg__items {
      &--1,
      &--3,
      &--4,
      &--5 {
        .bg__items__inner {
          background-position: 63% 120%;
        }
      }

      &--2 {
        .bg__items__inner {
          background-position: 23% 120%;
        }
      }
    }
  }
}

@include mq-down(md) {
  .logo {
    top: 50%;
    margin-top: -40%;
    margin-left: -50px;
    width: 100px;
  }

  .catch {
    top: 50%;
    margin-top: -20%;
    left: 50%;
    margin-left: -40%;
    width: 80%;
  }

  .bg {
    height: 80vh;

    .bg__items {
      &--1,
      &--3,
      &--4,
      &--5 {
        .bg__items__inner {
          background-position: 63% 120%;
        }
      }

      &--2 {
        .bg__items__inner {
          background-position: 23% 120%;
        }
      }
    }
  }
}

@include mq-down(md-h) {
  .logo {
    top: 50%;
    margin-top: -20%;
    margin-left: -40px;
    width: 80px;
  }

  .catch {
    top: 90%;
    margin-top: -30%;
    left: 50%;
    margin-left: -20%;
    width: 40%;
  }

  .bg {
    height: 100vh;

    .bg__items {
      &--1,
      &--3,
      &--4,
      &--5 {
        .bg__items__inner {
          background-position: 63% 120%;
        }
      }

      &--2 {
        .bg__items__inner {
          background-position: 23% 120%;
        }
      }
    }
  }
}

.info {
  .logo {
    top: 60px;
  }

  .catch {
    top: 200px;
  }

  .arrow {
    bottom: 0%;
  }

  @include mq-down(lg) {
    .logo {
      top: 80px;
      margin-top: 0;
    }

    .catch {
      top: 320px;
    }
  }

  @include mq-down(md) {
    .logo {
      top: 80px;
      margin-top: 0;
    }

    .catch {
      top: 260px;
    }

    .info-list {
      a {
        flex-direction: column;
      }

      .label {
        margin-bottom: 8px;
      }
    }
  }

  @include mq-down(md-h) {
    .logo {
      top: 40px;
      margin-top: 0;
    }

    .catch {
      display: none;
    }
  }
}

</style>
