<template>
  <div class="container">
    <rtg-heading
      title="RECRUIT"
      title-class="recruit"
    />

    <rtg-heading
      type="h3"
      title="未経験エンジニア、ウェルカム！"
      sub-title="Welcome new programmers!"
    />

    <rtg-content
      type="col"
      image-name="image3"
      image-alt="未経験から手に職を"
    >
      ルーテイジでは、
      <span>IT業界未経験者</span>
      向けの
      <span>プログラミング研修</span>
      を行なっております。
      <br>
      もちろん入社後の研修なので、お給料をもらいながら、プログラミングの技術を学ぶ事ができます。
      <br>
      誰でも最初は未経験。
      ルーテイジ
      <span>鬼教官</span>
      が行うプログラミング研修中、死に物狂いで勉強した者にだけ味わえる、
      <span>本気の遊び</span>
      教えます。
    </rtg-content>

    <div class="hr" />

    <rtg-heading
      type="h3"
      title="募集要項"
      sub-title="Recruitment contents"
    />

    <rtg-content
      type="table"
      :table-list-data="tableList"
    />
  </div>
</template>
<script>
import RtgHeading from './RtgHeading.vue'
import RtgContent from './RtgContent.vue'
const tableListData = [
  {
    dt: '勤務地',
    dd: '東京23区内のクライアント先になります。\n※業務はクライアント先企業での常駐勤務となる場合があります。'
  },
  {
    dt: '勤務時間',
    dd: '10:00～19:00（実労働時間8時間）\n※担当するプロジェクトにより異なります。'
  },
  {
    dt: '給与',
    dd: '実績・経験・能力を考慮の上、決定いたします。'
  },
  {
    dt: '休暇',
    dd: '休日・休暇:完全週休2日制(土・日)、祝日\n夏季休暇、有給休暇、ゴールデンウィーク、年末年始休暇、慶弔休暇\n※年間休日125日以上'
  },
  {
    dt: '福利厚生',
    dd: '各種社会保険完備\n資格取得保証金'
  },
  {
    dt: 'プログラミング研修',
    dd: 'プログラミング技術の習得に向けた研修制度を設けております。\n未経験の方でも、約2ヶ月間プログラミングの基礎を学べ\n実務に直結したスキルと知識を身に付ける事ができます。'
  },
  {
    dt: '教育制度',
    dd: '各種フォローアップ、勉強会実施等'
  }
]
export default {
  name: 'App',
  components: {
    RtgHeading,
    RtgContent
  },
  data () {
    return {
      tableList: tableListData
    }
  }
}
</script>
