<template>
  <header class="Rtg-header">
    <a
      class="Rtg-header__logo"
      href="#top"
    >
      <rtg-svg-image
        name="svgLogoSymbol"
        class="large"
      />
      <rtg-svg-image
        name="svgLogoType"
        class="small"
      />
      <h1>
        ルーテイジ株式会社
      </h1>
    </a>
    <nav class="Rtg-header__nav">
      <div
        class="menu-icon"
        :class="{'is-active': isActive}"
        @click="$_clickMenu"
      >
        <i class="menu-icon__inner" />
        <i class="menu-icon__inner" />
        <i class="menu-icon__inner" />
      </div>
      <rtg-drawer
        :show-drawer="isShow"
        :nav-list-data="navList"
        @click="$_clickMenu"
      />
      <rtg-nav-list :nav-list-data="navList" />
    </nav>
  </header>
</template>
<script>
import RtgDrawer from './RtgDrawer.vue'
import RtgNavList from './RtgNavList.vue'
import RtgSvgImage from './RtgSvgImage.vue'
const navListData = [
  {
    text: 'CONCEPT',
    id: 'concept'
  },
  {
    text: 'RECRUIT',
    id: 'recruit'
  },
  {
    text: 'FEATURE',
    id: 'feature'
  },
  {
    text: 'COMPANY',
    id: 'company'
  },
  {
    text: 'PARTNER',
    id: 'partner'
  }
]
export default {
  components: {
    RtgDrawer,
    RtgNavList,
    RtgSvgImage
  },
  props: {
    showDrawer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      navList: navListData,
      isShow: false,
      isActive: false
    }
  },
  computed: {
    computedShowDrawer: {
      get () {
        return this.isShow
      },
      set (value) {
        this.$emit('update:isShow', value)
      }
    }
  },
  methods: {
    $_clickMenu () {
      this.isShow = !this.isShow
      this.isActive = !this.isActive
    }
  }
}
</script>
<style scoped lang="scss">
.Rtg-header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  z-index: 3;

  .Rtg-header__logo {
    width: 120px;
    margin: 16px 0 0 16px;
    display: block;
    text-decoration: none;

    svg {
      fill: white;
    }

    .large {
      width: 120px;
      height: 30px;
    }

    .small {
      display: none;
      width: 48px;
      height: 48px;
    }

    h1 {
      display: flex;
      justify-content: center;
      color: white;
      font-weight: $fw-R;
      font-size: 10px;
    }
  }

  .Rtg-header__nav {
    height: 80px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .menu-icon {
    display: none;
    width: 80px;
    height: 80px;
    top: 0;
    position: absolute;
    right: 0;
    padding: 24px;
    box-sizing: border-box;
    z-index: 100;
    cursor: pointer;

    &__inner {
      display: block;
      margin-top: 6px;

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        width: 100%;
        -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
        transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
        border-radius: 99px;
        height: 2px;
        background: $shade;
      }
    }

    &.is-active {
      .menu-icon__inner {
        &:nth-of-type(1) {
          transform: translateY(5px) rotate(-45deg);
        }

        &:nth-of-type(2) {
          opacity: 0;
        }

        &:nth-of-type(3) {
          transform: translateY(-10px) rotate(45deg);
        }
      }
    }
  }
}

@include mq-down(lg) {
  .Rtg-header {
    .Rtg-header__logo {
      .large,
      h1 {
        display: none;
      }

      .small {
        display: block;
      }
    }

    .Rtg-header__nav {
      .menu-icon {
        display: block;
      }

      nav {
        display: none;
      }
    }
  }
}

</style>
