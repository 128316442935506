<template>
  <div class="container">
    <rtg-heading
      title="COMPANY"
      title-class="company"
    />
    <rtg-heading
      type="h3"
      title="会社概要"
      sub-title="Company profile"
    />

    <rtg-content
      type="table"
      :table-list-data="tableList1"
    />

    <rtg-heading
      type="h3"
      title="所在地"
      sub-title="MAP"
    />

    <rtg-content type="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12967.210243565287!2d139.6913741!3d35.6572358!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4383d3ff7555dca8!2z44Or44O844OG44Kk44K4!5e0!3m2!1sja!2sjp!4v1500584959229"
        loading="lazy"
        width="100%"
        height="450"
        frameborder="0"
        style="border: 0;"
        allowfullscreen=""
      />
    </rtg-content>

    <div class="hr" />

    <rtg-heading
      type="h3"
      title="沿革"
      sub-title="History"
    />

    <rtg-content
      type="table"
      :table-list-data="tableList2"
    />
  </div>
</template>

<script>
import RtgHeading from './RtgHeading.vue'
import RtgContent from './RtgContent.vue'
const tableListData1 = [
  {
    dt: '名称',
    dd: 'ルーテイジ株式会社'
  },
  {
    dt: '本社',
    dd: '〒150-0045\n東京都渋谷区神泉町20-24 BRICKS 8F'
  },
  {
    dt: '設立',
    dd: '2008年1月4日'
  },
  {
    dt: '資本金',
    dd: '3,500,000円'
  },
  {
    dt: '代表者',
    dd: '代表取締役社長  三上 潤'
  },
  {
    dt: '取締役',
    dd: '副社長  瓜生 正太郎\n吉田 将臣\n井手 悟郎'
  },
  {
    dt: '決算月',
    dd: '12月'
  },
  {
    dt: '主な事業',
    dd: 'コンピュータシステムの構築・保守\nシステムコンサルティングサービス\nDB・ソフトウェア開発\n人材サービス'
  },
  {
    dt: '取引銀行',
    dd: '三菱東京UFJ銀行中目黒支店'
  },
  {
    dt: '所在地',
    dd: '〒150-0045 東京都渋谷区神泉町20-24 BRICKS 8F'
  },
  {
    dt: 'TEL',
    dd: '03-6416-9490(代表)'
  },
  {
    dt: 'FAX',
    dd: '03-6416-9491'
  },
  {
    dt: 'ACCESS',
    dd: '京王井の頭線神泉駅南口改札 徒歩5分'
  }
]
const tableListData2 = [
  {
    dt: '2013年4月',
    dd: 'ルーテイジ株式会社へ商号変更'
  },
  {
    dt: '2010年2月',
    dd: '東京都渋谷区神泉に事務所移転'
  },
  {
    dt: '2009年12月',
    dd: '資本金350万円に増資'
  },
  {
    dt: '2009年8月',
    dd: '東京都渋谷区渋谷に事務所移転'
  },
  {
    dt: '2008年1月',
    dd: '東京都目黒区にてルーテイジ・システム・コンサルティング株式会社設立'
  }
]
export default {
  name: 'App',
  components: {
    RtgHeading,
    RtgContent
  },
  data () {
    return {
      tableList1: tableListData1,
      tableList2: tableListData2
    }
  }
}
</script>
