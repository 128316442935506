<template>
  <div class="container">
    <rtg-heading
      title="FEATURE"
      title-class="feature"
    />

    <!-- <rtg-heading
      type="h3"
      title="社員インタビュー"
    />
    <rtg-content>
      coming soon...
    </rtg-content>

    <div class="hr" />
    -->

    <rtg-heading
      type="h3"
      title="自社開発プロダクト"
      sub-title="Product"
    />

    <rtg-content
      type="banner"
      :banner-list-data="bannerList"
    />
  </div>
</template>
<script>
import RtgHeading from './RtgHeading.vue'
import RtgContent from './RtgContent.vue'
const bannerListData = [
  { alt: 'こもれび家族' },
  { alt: 'サコージュ' },
  { alt: 'ギグノートアプリ' },
  { alt: 'ギグノート' },
  { alt: 'ボクサーズロード' },
  { alt: 'ソラメソカメラ' },
  { alt: 'color 2 code' },
  { alt: 'プランニングポーカー' }
]
export default {
  name: 'App',
  components: {
    RtgHeading,
    RtgContent
  },
  data () {
    return {
      bannerList: bannerListData
    }
  }
}
</script>
