<template>
  <h2
    v-if="type === 'h2'"
    class="rtg-heading rtg-heading--h2"
    :class="`rtg-heading--${titleClass}`"
  >
    <span class="bg">
      <span class="bg__deco1" />
      <span class="bg__deco2" />
      <span class="bg__deco3" />
      <span class="bg__deco4" />
    </span>
    <div class="image" />
    <span class="title">
      {{ title }}
    </span>
  </h2>
  <div
    v-else-if="type === 'h3'"
    class="rtg-heading rtg-heading--h3"
  >
    <h3>
      {{ title }}
    </h3>
    <p>
      {{ subTitle }}
    </p>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    titleClass: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'h2'
    }
  }
}
</script>
<style scoped lang="scss">
.rtg-heading {
  &--h2 {
    height: 130px;
    position: relative;
    transform: rotate(-4deg);
    margin-left: -16px;
    margin-bottom: 60px;
    font-weight: $fw-B;
    filter: drop-shadow(11px 12px 0 rgba(0, 0, 0, 0.3));

    .title {
      font-size: 80px;
      position: absolute;
      top: 0;
      padding-top: 5px;
      left: 125px;
      display: block;
    }

    .image {
      width: 100px;
      height: 100px;
      background-size: 100%;
      position: absolute;
      top: 50%;
      left: 30px;
      margin-top: -50px;
    }

    .bg {
      width: 560px;
      height: 130px;
      display: block;
      background: $shade;

      span {
        position: absolute;
        left: 560px;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent transparent transparent $shade;
      }

      .bg__deco1 {
        top: 0;
        border-width: 0 0 21px 11px;
      }

      .bg__deco2 {
        top: 20px;
        border-width: 21px 0 33px 33px;
      }

      .bg__deco3 {
        top: 71px;
        border-width: 21px 0 33px 20px;
      }

      .bg__deco4 {
        bottom: 0;
        border-width: 14px 0 0 20px;
      }
    }
  }

  &--concept {
    .title { color: $rtgBlue; }
    .image { background-image: url(~@/assets/images/icon-concept.svg); }
  }

  &--recruit {
    .title { color: $rtgOrange; }
    .image { background-image: url(~@/assets/images/icon-recruit.svg); }
  }

  &--feature {
    .title { color: $rtgPurple; }
    .image { background-image: url(~@/assets/images/icon-feature.svg); }
  }

  &--company {
    .title { color: $rtgGreen; }
    .image { background-image: url(~@/assets/images/icon-company.svg); }
  }

  &--partner {
    .title { color: $rtgRed; }
    .image { background-image: url(~@/assets/images/icon-partner.svg); }
  }

  &--h3 {
    width: 100%;
    margin-bottom: 32px;
    padding: 40px 0;
    text-align: center;

    h3 {
      font-size: 60px;
      font-weight: $fw-B;
      color: $rtgYellow;
      filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.5));
    }

    p {
      background-color: rgba(0, 0, 0, 0.3);
      color: $shade;
      font-weight: $fw-M;
      font-size: 20px;
      border-radius: 99px;
      padding: 5px 30px;
      display: inline-block;
      position: relative;
      top: 10px;
    }
  }
}

@include mq-down(xl) {
  .rtg-heading {
    &--h2 {
      transform: rotate(-4deg) scale(0.8);
      left: -10%;
      margin-bottom: 20px;
    }

    &--h3 {
      h3 {
        padding: 0 20px;
        font-size: 60px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}

@include mq-down(lg) {
  .rtg-heading {
    &--h2 {
      transform: rotate(-4deg) scale(0.6);
      left: -20%;
      margin-bottom: 20px;
    }

    &--h3 {
      padding: 20px 0;

      h3 {
        padding: 0 20px;
        font-size: 50px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}

@include mq-down(md) {
  .rtg-heading {
    &--h2 {
      transform: rotate(-4deg) scale(0.5);
      left: -25%;
      margin-bottom: 20px;
    }

    &--h3 {
      h3 {
        padding: 0 10px;
        font-size: 30px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}
</style>
