<template>
  <div class="rtg-content">
    <div
      v-if="type === 'col'"
      class="rtg-content__col"
    >
      <div class="area-image">
        <img
          :src="`/img/${imageName}.jpg`"
          :alt="imageAlt"
          width="1200"
          height="680"
        >
      </div>
      <div class="area-explain">
        <p>
          <slot />
        </p>
      </div>
    </div>
    <div
      v-else-if="type === 'table'"
      class="rtg-content__table"
    >
      <dl
        v-for="(item, index) in tableListData"
        :key="index"
      >
        <dt>
          {{ item.dt }}
        </dt>
        <dd>
          {{ item.dd }}
        </dd>
      </dl>
    </div>
    <div
      v-else-if="type === 'map'"
      class="rtg-content__map"
    >
      <slot />
    </div>
    <div
      v-else-if="type === 'banner'"
      class="rtg-content__banner"
    >
      <img
        v-for="(item, index) in bannerListData"
        :key="index"
        :src="`/img/banner${index}.jpg`"
        :alt="item.alt"
        width="960"
        height="480"
      >
    </div>
    <div
      v-else
      class="rtg-content__row"
    >
      <div class="area-explain">
        <p>
          <slot />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    imageName: {
      type: String,
      default: ''
    },
    imageAlt: {
      type: String,
      default: ''
    },
    tableListData: {
      type: Array,
      default: () => []
    },
    bannerListData: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="scss">

.rtg-content {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px 40px;

  .area-explain {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 10px;
    box-sizing: border-box;

    p {
      color: $shade;
      font-size: 24px;
      letter-spacing: 1px;
      line-height: 1.8;
    }
  }

  .rtg-content__col {
    display: flex;
    align-items: flex-start;

    .area-explain {
      width: 50%;
      padding: 20px;
      margin: 5px;
    }

    .area-image {
      width: 50%;
      padding: 5px;

      img {
        height: 100%;
      }
    }
  }
}

@include mq-down(xl) {
  .rtg-content {
    .area-explain {
      p {
        font-size: 20px;
      }
    }

    .rtg-content__col {
      .area-explain {
        margin-bottom: 20px;
      }

      .area-image {
        margin-bottom: 20px;
      }
    }
  }
}

@include mq-down(lg) {
  .rtg-content {
    .rtg-content__col {
      display: block;

      .area-explain {
        width: 100%;
        margin: 0;

        p {
          font-size: 20px;
        }
      }

      .area-image {
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}

@include mq-down(md) {
  .rtg-content {
    .area-explain {
      p {
        font-size: 16px;
      }
    }
  }
}

.rtg-content__map {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px;
  box-sizing: border-box;
  max-width: 960px;
  margin: 0 auto;
}

.rtg-content__table {
  box-sizing: border-box;
  padding: 32px;
  margin: 0 auto 32px;
  color: $shade;
  font-size: 20px;
  max-width: 960px;
  background-color: rgba(0, 0, 0, 0.3);

  dl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    white-space: pre-wrap;

    dt {
      font-weight: $fw-M;
      text-align: right;
      width: 30%;
      padding: 24px 16px;
    }

    dd {
      font-weight: $fw-R;
      padding: 24px 16px;
      width: 70%;
    }
  }
}

@include mq-down(md) {
  .rtg-content__table {
    padding: 0;
    margin: 0 auto 16px;

    dl {
      display: block;

      dt {
        text-align: center;
        width: 100%;
        padding: 12px 16px;
        font-size: 16px;
      }

      dd {
        display: flex;
        justify-content: center;
        padding: 12px 16px 24px;
        width: 100%;
        font-size: 16px;
      }
    }
  }
}

.rtg-content__banner {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  img {
    width: 33%;
    height: 100%;
    padding: 4px;
  }
}

@include mq-down(lg) {
  .rtg-content__banner {
    justify-content: center;

    img {
      width: 50%;
    }
  }
}

@include mq-down(md) {
  .rtg-content__banner {
    img {
      width: 100%;
    }
  }
}

</style>
