<template>
  <nav id="menu">
    <a
      v-for="(item, index) in navListData"
      :key="index"
      :data-menuanchor="`${item.id}`"
      :href="`#${item.id}`"
    >
      <div
        class="image"
        :class="`image--${item.id}`"
      />
      <div class="text">
        {{ item.text }}
      </div>
    </a>
  </nav>
</template>
<script>
export default {
  props: {
    navListData: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style scoped lang="scss">
nav {
  display: flex;
  padding: 10px;

  a {
    display: block;
    text-decoration: none;
    color: $shade;
    width: 80px;
    height: 80px;
    border-radius: 4px;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }

    .image {
      width: 48px;
      height: 48px;
      background-size: 100%;
      margin: 6px auto 0;
      &--concept { background-image: url(~@/assets/images/icon-concept.svg); }
      &--recruit { background-image: url(~@/assets/images/icon-recruit.svg); }
      &--feature { background-image: url(~@/assets/images/icon-feature.svg); }
      &--company { background-image: url(~@/assets/images/icon-company.svg); }
      &--partner { background-image: url(~@/assets/images/icon-partner.svg); }
    }

    .text {
      width: 80px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
    }

    &.active {
      background: $shade;

      .text {
        color: $rtgBlue;
      }
    }
  }
}
</style>
