<template>
  <div class="container">
    <rtg-heading
      title="PARTNER"
      title-class="partner"
    />

    <rtg-heading
      type="h3"
      title="ビジネスパートナー企業様へ"
      sub-title="To business partner"
    />

    <rtg-content
      type="col"
      image-name="image4"
      image-alt="ビジネスパートナー"
    >
      弊社では、得意な技術分野を生かし、互いの技術やノウハウ、価値観を共有して発展、成長に向けて共に協力しあえるパートナーシップを結びたいと考えています。
      <br>
      現在及び近い将来、ビジネスパートナーとしてご協力いただける方、弊社について話しを聞きたいと思って頂いた方。
      <br>
      弊社の企業理念にご賛同頂き、お客様に高い付加価値を提供し、新たなビジネスを開拓したいとお考えの企業様、個人事業主様を募集いたします。
    </rtg-content>

    <div class="hr" />

    <rtg-heading
      type="h3"
      title="お問い合せ"
      sub-title="Contact Us"
    />

    <rtg-content>
      システム開発案件をお持ちの場合は、弊社のエンジニアがお力になる事も可能です。
      <br>
      是非、お気軽にお問い合わせフォームからご一報下さい。
      <br>
      弊社営業からご返信いたします。
    </rtg-content>
    <rtg-button />

    <footer>
      <p>(c) Rootage.inc 2023</p>
    </footer>
  </div>
</template>
<script>
import RtgHeading from './RtgHeading.vue'
import RtgButton from './RtgButton.vue'
import RtgContent from './RtgContent.vue'

export default {
  name: 'App',
  components: {
    RtgHeading,
    RtgButton,
    RtgContent
  }
}
</script>

<style scoped lang="scss">

.button {
  margin: 20px auto 120px;
  position: relative;
}

footer {
  background: $primary-dark;
  height: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 10px;
    color: $shade;
  }
}
</style>
